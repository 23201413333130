import * as React from "react"
import { graphql } from "gatsby"
import ImageComponent from "../components/ImageComponent"
import TextSectionComponent from "../components/TextSectionComponent"
import "fontsource-source-sans-pro/300.css"
import "fontsource-source-sans-pro/400.css"
import "fontsource-source-sans-pro/400-italic.css"
import "fontsource-source-sans-pro/600.css"
import '../styles/main.scss'
import SEO from '../components/seo'
import NavComponent from '../components/NavComponent'

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO />
      <ImageComponent photo={data.photoIndex.childImageSharp.gatsbyImageData} page="index" />
      <NavComponent />
      <main>
        <TextSectionComponent
          header={<>Biografie</>}
        >
          <p>Bob Marsdon (1993, Amsterdam) is een toegewijd pianist die zowel graag solistisch als in kamermuziekverband optreedt. In 2021 sloot hij met een 9 zijn master af bij Slava Poprugin op het Koninklijk Conservatorium in Den Haag.</p>
          <p>Hoewel klassieke muziek nu zijn voornaamste aandacht krijgt is Bob geïnteresseerd in veel stijlen muziek. Dit neemt hij vervolgens mee als inspiratie in zijn uitvoeringen van klassieke muziek. Als kind speelde hij veel verschillende stijlen muziek op gehoor na, improviseerde en componeerde hij graag. Hij ontwikkelde een liefde voor progressieve rock, en speelde muziek van bijvoorbeeld Genesis, Yes en Emerson, Lake and Palmer. En al speelt hij deze muziek nog steeds graag, je zult hem vooral klassiek horen spelen in de concertzaal.</p>
          <p>Naast de piano heeft Bob een andere grote passie: programmeren. Na de middelbare school koos hij er dan ook voor om een dubbele bachelor wiskunde en informatica aan de Universiteit Leiden te volgen. Tijdens deze studie bloeide zijn relatie met klassieke muziek op en besloot hij om ook een carrière in muziek na te jagen. Na een jaar de minor Practicum Musicae bij Ksenia Kouzmenko gevolgd te hebben aan het conservatorium in Den Haag, vervolgde hij hier zijn studies met een bachelor bij Ellen Corver en een master bij Slava Poprugin. Tijdens zijn studie volgde hij masterclasses bij o.a. Ronald Brautigam, Pascal Devoyon, Severin von Eckardstein, Naum Grubert, David Kuyken, Denys Proshchayev, Pascal Rogé en Igor Roma.</p>
          <p>Bob speelt graag kamermuziek en maakt deel uit van een piano trio, opgericht in 2016 met Marjolein Spruit (viool) en Emma Warmelink (cello): het Zèta Trio. Met dit ensemble trad hij al vaak op. Zij wonnen in 2019 de Grote Kamermuziekprijs met een optreden waarbij zij muziek en IT combineerden. Met de app Zèta Trio Live is het mogelijk informatie mee te lezen, die synchroon loopt met de muziek die zij spelen. Het trio nam deel aan sessies van de European Chamber Music Academy en de Zeister Muziekdagen. Zij kregen masterclasses van onder andere Hatto Beyerle, Patrick Jüdt, het Jerusalem Quartet, Boris Giltburg en Elisabeth Leonskaja.</p>
          <p>Sinds juni 2017 mag hij een Kawai vleugel van het NMF bespelen.</p>
        </TextSectionComponent>
      </main>
    </>
  )
}

export default IndexPage

export const query = graphql`{
  photoIndex: file(relativePath: {eq: "photo-index.jpeg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
}
`