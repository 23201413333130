import * as React from 'react'

const TextSectionComponent = ({ header, children }) => (
  <div className="text-section">
    <h3 className="text-section__header">
      {header}
    </h3>
    <div className="text-section__text">
      {children}
    </div>
  </div>
)

export default TextSectionComponent